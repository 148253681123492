import React from "react";
import Contact from "../component/contact/contact";
import Try from "../component/includes/try/try";

function ContactPage() {
    return (
        <>
            <Contact />
            <Try />
        </>
    )
}

export default ContactPage;