import React from "react";
import Agenda from "../component/agenda/agenda";

function AgendaPage () {
    return(
        <>
            <Agenda/>
        </>
    )
}

export default AgendaPage;