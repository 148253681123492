import React from "react";
import Price from "../component/price/price";

function PricePage () {
    return(
        <div className="bg-secondary">
            <Price/>
        </div>
    )
}
export default PricePage;
