import React from "react";
import Login from "../component/Login/login";

function LogInPage () {
    return(
        <div>
            <Login/>
        </div>
    )
}

export default LogInPage;