import React from "react";
import Carousel from "../component/O365/carousel/carousel";
import O365 from "../component/O365/o365";

function O365Page() {
    return (
        <div>
            <Carousel />
            <O365 />
        </div>
    )
}

export default O365Page;