import React from "react";
import Workflow from "../component/workflow/workflow";

function WorkFlowPage() {
    return (
        <div>
            <Workflow />
        </div>
    )
}

export default WorkFlowPage;