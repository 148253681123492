import React from "react";
import ERP from '../component/erp/erp';

function ERPPage() {
    return (
        <>
            <ERP />
        </>
    )
}

export default ERPPage;