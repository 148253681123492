import React from "react";
import Reviews from "../component/reviews/reviews";
import Try from "../component/includes/try/try";

function ReviewsPage() {
    return (
        <>
            <Reviews />
            <Try />
        </>
    )
}

export default ReviewsPage;